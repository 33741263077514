import React from 'react'
import PropTypes from 'prop-types'
import { getUrl } from '../../helpers/url'
import StyledTooltip from './style'
import { TextLink } from '../TextLink'
import { useTranslation } from '../../hooks/useTranslation'

const Tooltip = ({ text, link }) => {
  const bodyText = text ? text : null
  const getTranslation = useTranslation()

  return (
    <StyledTooltip>
      <StyledTooltip.Trigger>?</StyledTooltip.Trigger>
      <StyledTooltip.Content>
        <StyledTooltip.ContentInner>
          <span dangerouslySetInnerHTML={{ __html: bodyText }} />
          {link && (
            <TextLink
              to={getUrl(link)}
              text={getTranslation('readMoreUppercaseText')}
              underline
            />
          )}
        </StyledTooltip.ContentInner>
      </StyledTooltip.Content>
    </StyledTooltip>
  )
}

Tooltip.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
}

export { Tooltip }
