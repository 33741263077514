import React from 'react'
import PropTypes from 'prop-types'
import StyledSpecificationsTable from './style'
import { Table } from '../Table'
import { Tooltip } from '../Tooltip'
import { TextLink } from '../TextLink'
import { Divider } from '../Divider'
import { SPACING } from '../../helpers/constants'

const SpecificationsTable = props => {
  const { productSpecTree } = props

  return (
    <StyledSpecificationsTable>
      <Table>
        <Table.Body>
          {Object.entries(productSpecTree).map(
            (firstLevel, firstLevelIndex) => {
              const [key, value] = firstLevel

              return (
                <Table.Tr key={key}>
                  <Table.Th>
                    {firstLevelIndex > 0 && (
                      <Divider
                        size={{
                          desktop: SPACING.NONE,
                          tablet: SPACING.NONE,
                          mobile: SPACING.MEDIUM,
                        }}
                      />
                    )}
                    <p>{key}</p>
                  </Table.Th>

                  <Table.Td>
                    <Table>
                      <Table.Body>
                        {Object.entries(value).map(
                          (secondLevel, secondLevelIndex) => {
                            const [key, value] = secondLevel
                            const {
                              published_in_dictionary,
                              description,
                              spec_page_url,
                            } = value
                            return (
                              <Table.Tr key={key}>
                                <Table.Th>
                                  <div>
                                    <span>{key}</span>
                                    {description.length > 0 && (
                                      <Tooltip
                                        text={description ? description : ''}
                                        link={spec_page_url}
                                      />
                                    )}
                                  </div>
                                </Table.Th>
                                <Table.Td>
                                  {value.specs.map(
                                    (specTerm, specTermIndex) => {
                                      const { name, url } = specTerm
                                      return (
                                        <span key={`term${specTermIndex}`}>
                                          {url ? (
                                            <StyledSpecificationsTable.TextLink
                                              href={url}
                                            >
                                              {name}
                                            </StyledSpecificationsTable.TextLink>
                                          ) : (
                                            name
                                          )}
                                          {specTermIndex <
                                            value.specs.length - 1 && ', '}
                                        </span>
                                      )
                                    }
                                  )}
                                </Table.Td>
                              </Table.Tr>
                            )
                          }
                        )}
                      </Table.Body>
                    </Table>
                  </Table.Td>
                </Table.Tr>
              )
            }
          )}
        </Table.Body>
      </Table>
    </StyledSpecificationsTable>
  )
}

SpecificationsTable.propTypes = {
  productSpecTree: PropTypes.object,
}

export { SpecificationsTable }
