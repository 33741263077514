import styled from 'styled-components'
import { Link } from 'gatsby'
import {
  spacing,
  colors,
  maxWidths,
  mq,
  typography,
} from '../../styles/utilities/variables'

const StyledBreadcrumbs = styled.nav`
  border-bottom: 1px solid ${colors.secondaryLightBlue};
  padding: ${spacing.base} 0;
  font-family: ${typography.secondaryFont};
`

StyledBreadcrumbs.List = styled.ol`
  margin: 0;
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  max-width: ${props =>
    props.wide ? maxWidths.wideContainer : maxWidths.container};
  margin: 0 auto;
  width: 100%;
  padding: 0 ${spacing.large};
  position: relative;
  @media ${mq.tablet} {
    padding: 0 ${spacing.medium};
  }
`

StyledBreadcrumbs.ListSeperator = styled.li`
  margin: 0 ${spacing.xSmall};
  font-size: 15px;
  color: ${colors.primaryDarkBlue};

  & div {
    display: flex;
  }

  @media (max-width: 979px) {
    margin: 0;

    &:not(:first-child) {
      display: none;
    }
  }
`

StyledBreadcrumbs.ListItem = styled.li`
  font-size: 15px;
  color: ${colors.primaryDarkBlue};
  @media (max-width: 979px) {
    &:not(:nth-last-child(3)) {
      display: none;
    }
  }
`

StyledBreadcrumbs.Link = styled(Link)`
  color: ${colors.primaryDarkBlue};
`

StyledBreadcrumbs.Item = styled.span`
  color: rgba(0, 49, 69, 0.7);
`

export default StyledBreadcrumbs
