import styled from 'styled-components'
import {
  spacing,
  mq,
  colors,
  typography,
} from '../../styles/utilities/variables'
import StyledTable from '../Table/style'
import StyledTooltip from '../Tooltip/style'

const StyledSpecificationsTable = styled.div`
  @media ${mq.mobile} {
    & ${StyledTable.Td}, ${StyledTable.Th}, ${StyledTable.Tr} {
      width: 100%;
      padding: 0;
      margin: 0;
    }
  }

  & ${StyledTable.Th} {
    color: ${colors.primaryDarkBlue};
    font-family: ${typography.primaryFont};
    font-size: ${typography.sizes.base};

    & span {
      color: ${colors.black};
      font-family: ${typography.secondaryFont};
      font-size: ${typography.sizes.small};
    }

    & p {
      margin-bottom: 0;
    }
  }

  & > ${StyledTable} {
    border-top: 1px solid ${colors.secondaryLightBlue};

    @media ${mq.mobile} {
      border: none;
    }

    ${StyledTable.Th} {
      width: 25%;

      @media ${mq.mobile} {
        width: 100%;
        border-top: 1px solid ${colors.secondaryLightBlue};
        padding-top: 8px;
      }
    }
  }

  ${StyledTable.Tr} {
    &:last-child {
      border-bottom: none;
    }
  }

  ${StyledTable.Td} {
    & > ${StyledTable} {
      margin: -${spacing.xSmall} 0;

      @media ${mq.mobile} {
        margin: 0;
      }

      ${StyledTable.Th} {
        width: 40%;

        @media ${mq.mobile} {
          width: 100%;
          border-top: 1px solid ${colors.secondaryLightBlue};
          flex-direction: column;
          padding-top: ${spacing.xSmall};
          margin-top: ${spacing.xSmall};
          position: relative;

          & div {
            flex-direction: row;
          }
        }

        & > div {
          display: flex;
          align-items: center;

          span {
            margin-right: ${spacing.xSmall};
          }

          ${StyledTooltip} {
            top: -0.2rem;
          }
        }
      }
    }
  }
`

StyledSpecificationsTable.TextLink = styled.a`
  color: #41909e;

  &:focus,
  &:active {
    color: #41909e;
  }

  &:hover {
    color: ${colors.primaryDarkBlue};
    text-decoration: underline;
  }
`

export default StyledSpecificationsTable
