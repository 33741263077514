import styled from 'styled-components'
import {
  colors,
  spacing,
  radius,
  typography,
  cssEasings,
  mq,
} from '../../styles/utilities/variables'

const StyledTooltip = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;

  @media ${mq.mobile} {
    position: static;
  }
`

StyledTooltip.Trigger = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: ${spacing.small};
  height: ${spacing.small};
  background: ${colors.secondaryLightBlue};
  border-radius: 50%;
  font-family: ${typography.secondaryFont};
  font-size: ${typography.sizes.xxSmall};
  font-weight: bold;
`

StyledTooltip.Content = styled.div`
  position: absolute;
  visibility: hidden;
  z-index: 1;
  left: -10px;
  bottom: 0;
  padding-bottom: 2.5rem;
  opacity: 0;
  transform: translateY(1rem);
  transition: transform 0.4s ${cssEasings.easeOutQuart}, opacity 0.25s;

  ${StyledTooltip}:hover & {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
`

StyledTooltip.ContentInner = styled.div`
  width: 28rem;
  background: ${colors.white};
  border-radius: ${radius.medium};
  padding: ${spacing.base};
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  font-family: ${typography.primaryFont};
  font-size: ${typography.sizes.small};
  text-transform: none;
  cursor: auto;
`

export default StyledTooltip
