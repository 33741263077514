import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import {
  colors,
  spacing,
  mq,
  typography,
} from '../../styles/utilities/variables'

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: left;

  @media ${mq.mobile} {
    border: none;
  }
`

StyledTable.Head = styled.thead`
  @media ${mq.mobile} {
    left: -9999px;
    position: absolute;
    visibility: hidden;
  }
`

StyledTable.Body = styled.tbody``

StyledTable.Tr = styled.tr`
  border-bottom: 1px solid ${colors.secondaryLightBlue};
  font-family: ${typography.secondaryFont};
  font-size: ${typography.sizes.small};
  line-height: 1.5;

  @media ${mq.mobile} {
    border-bottom: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: ${spacing.medium};
    && {
      background-color: transparent;
    }
  }

  th {
    text-transform: uppercase;
    letter-spacing: 0.04em;
  }
`

StyledTable.Th = styled.th`
  vertical-align: top;
  padding: ${spacing.xSmall} 0;
`
StyledTable.Td = styled.td`
  vertical-align: top;
  padding: ${spacing.xSmall} 0;

  @media ${mq.mobile} {
    margin: 0 -1px -1px 0;
    position: relative;
    width: 50%;
  }

  ${props =>
    props.label &&
    css`
      .label {
        background-color: ${colors.lightGrey};
        color: ${colors.black};
        display: none;
        font-size: ${typography.sizes.xSmall};
        font-weight: bold;
        padding: ${spacing.xSmall} ${spacing.small};
        position: absolute;
        letter-spacing: 0.5px;
        top: 0;
        left: 0;
      }
      @media ${mq.mobile} {
        padding-top: ${spacing.large};
        .label {
          display: block;
        }
      }
    `}
`

StyledTable.Link = styled(Link)`
  font-family: ${typography.primaryFont};
  text-transform: none;
  font-size: ${typography.sizes.small};
  border-bottom: 1px solid ${colors.secondaryLightBlue};
  color: ${colors.primaryDarkBlue};
`

export default StyledTable
