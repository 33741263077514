import React from 'react'
import PropTypes from 'prop-types'
import StyledPageGallery from './style'
import { Container } from '../Container'
import { Slider } from '../Slider'

const PageGallery = props => {
  const { images, mediaItems, noMargin = false, hideZoom = false } = props
  const imagesArr = images
    ? images.map(item => {
        return {
          image: item,
          video: item.video === undefined ? null : item.video,
        }
      })
    : null
  const medaItemsArr = mediaItems
    ? mediaItems
        .filter(item => (item.image ? true : false))
        .map(item => {
          return {
            image: item.image ? item.image.makeImage : null,
            video: item.youtubeUrl === undefined ? null : item.youtubeUrl,
          }
        })
    : null
  const combinedMediaArray =
    medaItemsArr && imagesArr
      ? imagesArr.concat(medaItemsArr)
      : imagesArr
      ? imagesArr
      : medaItemsArr

  return (
    <StyledPageGallery {...props}>
      <Container>
        {combinedMediaArray && combinedMediaArray.length > 0 && (
          <Slider images={combinedMediaArray} hideZoom={hideZoom} />
        )}
      </Container>
    </StyledPageGallery>
  )
}

PageGallery.propTypes = {
  images: PropTypes.array,
  mediaItems: PropTypes.array,
}

export { PageGallery }
