import React from 'react'
import PropTypes from 'prop-types'
import StyledBreadcrumbs from './style'
import { getUrl } from '../../helpers/url'
import { getIsClient } from '../../helpers/utilities'
import { useResponsive } from '../../hooks/useResponsive'
import { useHasMounted } from '../../hooks/useHasMounted'
import { Icon } from '../Icon'

const Breadcrumbs = ({ breadcrumbs }) => {
  const { windowSize } = useResponsive()
  const hasMounted = useHasMounted()

  const schema = getIsClient()
    ? {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: breadcrumbs.map((item, key) => {
          if (window.location.pathname === item.url) {
            return {
              '@type': 'ListItem',
              position: key + 1,
              name: item.text,
            }
          } else {
            return {
              '@type': 'ListItem',
              position: key + 1,
              name: item.text,
              item: window.location.origin + item.url,
            }
          }
        }),
      }
    : {}

  return (
    <>
      {schema && (
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
        />
      )}
      <StyledBreadcrumbs aria-label='breadcrumb'>
        <StyledBreadcrumbs.List>
          {hasMounted ? (
            breadcrumbs.map((item, key) => {
              return (
                <React.Fragment key={key}>
                  {windowSize.width <= 979 && (
                    <StyledBreadcrumbs.ListSeperator>
                      <Icon size={16} icon={'keyboard_arrow_left'} />
                    </StyledBreadcrumbs.ListSeperator>
                  )}
                  {key > 0 && windowSize.width >= 980 && (
                    <StyledBreadcrumbs.ListSeperator>
                      {' '}
                      /{' '}
                    </StyledBreadcrumbs.ListSeperator>
                  )}
                  <StyledBreadcrumbs.ListItem>
                    <Breadcrumb
                      to={getUrl(item.url)}
                      text={item.text}
                      currentPage={key === breadcrumbs.length - 1}
                    />
                  </StyledBreadcrumbs.ListItem>
                </React.Fragment>
              )
            })
          ) : (
            <StyledBreadcrumbs.ListItem
              dangerouslySetInnerHTML={{ __html: '&nbsp;' }}
            />
          )}
        </StyledBreadcrumbs.List>
      </StyledBreadcrumbs>
    </>
  )
}

const Breadcrumb = props => {
  const { currentPage, to, text } = props

  return currentPage ? (
    <StyledBreadcrumbs.Item aria-current={'page'}>
      {text}
    </StyledBreadcrumbs.Item>
  ) : (
    <StyledBreadcrumbs.Link to={to}>{text}</StyledBreadcrumbs.Link>
  )
}

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.array,
}

export { Breadcrumbs }
