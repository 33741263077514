import React, { useContext, useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import {
  AppContext,
  addProductToCartAndOpenCart,
  doFetchStockStatus,
} from '../../config/context'
import { parseSeo } from '../../helpers/seo'
import { SPACING, COLORS, PRODUCT_TYPES } from '../../helpers/constants'
import Layout from '../../views/Layout'
import SEO from '../../views/Seo'
import { PageGallery } from '../../components/PageGallery'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { IndentWrapper } from '../../blocks/IndentWrapper'
import { Heading } from '../../components/Heading'
import { Divider } from '../../components/Divider'
import { Content } from '../../components/Content'
import { Text } from '../../components/Text'
import { Container } from '../../components/Container'
import { FeatureGrid } from '../../components/FeatureGrid'
import { PageContent } from '../../components/PageContent'
import { ColorPicker } from '../../components/ColorPicker'
import { SectionHeader } from '../../components/SectionHeader'
import { Grid } from '../../components/Grid'
import { Col } from '../../components/Col'
import { TextLink } from '../../components/TextLink'
import { SpecificationsTable } from '../../components/SpecificationsTable'
import { MakeImage } from '../../components/MakeImage'
import { ProductCard } from '../../components/ProductCard'
import StyledProductCard from '../../components/ProductCard/style'
import { getUrl } from '../../../node/functions'
import { Button } from '../../components/Button'
import { useTranslation } from '../../hooks/useTranslation'
import { formatPrice } from '../../helpers/utilities'
import { purchaseEnabled } from '../../helpers/checkout'
import { gtag } from '../../helpers/google'

export default ({ data }) => {
  const [state, dispatch] = useContext(AppContext) // eslint-disable-line
  const { products, stockStatus } = state

  const getTranslation = useTranslation()

  const product = data.wordpress.product
  const {
    id,
    type,
    productId,
    seo,
    acfAttributes: {
      productTitle,
      productFeatures,
      productSerie,
      productSerieImage,
      partialPayment,
    },
    name,
    price,
    sku,
    shortDescription,
    productSpecTree,
    variations,
  } = product

  const getInitialProductId = () => {
    if (type === PRODUCT_TYPES.VARIABLE) {
      return variations &&
        variations.nodes &&
        variations.nodes[0] &&
        variations.nodes[0].variationId
        ? variations.nodes[0].variationId
        : productId
    }

    // Simple product and any other right now
    return productId
  }

  // Store either simple products productId or selected variations variationId
  const [currentProductId, setCurrentProductId] = useState(getInitialProductId)

  const parsedProductSpecTree = (raw => {
    try {
      return JSON.parse(raw)
    } catch (err) {
      return {}
    }
  })(productSpecTree)

  // First image
  // const productImage =
  //   image && image.makeImageLarge ? [image.makeImageLarge] : []

  // const images =
  //   galleryImages && galleryImages.nodes
  //     ? galleryImages.nodes.reduce((acc, node) => {
  //         return node && node.makeImage ? [...acc, node.makeImage] : acc
  //       }, [])
  //     : null

  const addToCart = () => {
    const dataLayerData = {
      ecommerce: {
        items: [
          {
            item_name: name,
            item_id: getVariation().sku,
            price: getPrice(),
            item_brand: 'Ecoride',
            item_category: 'Bike',
            index: 1,
            quantity: 1,
          },
        ],
      },
    }
    gtag('add_to_cart', dataLayerData)
    dispatch(addProductToCartAndOpenCart(currentProductId))
  }

  if (type === PRODUCT_TYPES.VARIABLE) {
    //console.log('variations', variations)
  }

  const getVariation = () => {
    return (
      variations &&
      variations.nodes &&
      variations.nodes.filter(
        variation => variation.variationId === currentProductId
      )[0]
    )
  }

  const getName = () => {
    return type === PRODUCT_TYPES.VARIABLE && getVariation()
      ? getVariation().name
      : name
  }

  const getPrice = () => {
    return type === PRODUCT_TYPES.VARIABLE && getVariation()
      ? getVariation().finalPrice
      : price
  }

  const getSku = () => {
    return type === PRODUCT_TYPES.VARIABLE && getVariation()
      ? getVariation().sku
      : sku
  }

  const getImages = () => {
    if (type === PRODUCT_TYPES.VARIABLE && getVariation()) {
      // Variation main image
      let variationMainImage =
        getVariation().image && getVariation().image.makeImageLarge
          ? [getVariation().image.makeImageLarge]
          : []

      // And variation gallery images
      let variationGalleryImages =
        getVariation().galleryImages &&
        getVariation().galleryImages.nodes &&
        getVariation().galleryImages.nodes.length > 0
          ? getVariation().galleryImages.nodes.reduce((acc, node) => {
              return node && node.makeImage ? [...acc, node.makeImage] : acc
            }, [])
          : []

      return [...variationMainImage, ...variationGalleryImages]
    } else {
      return []
    }
  }

  const getVariationStock = () => {
    if (stockStatus && stockStatus.length > 0) {
      if (type === PRODUCT_TYPES.VARIABLE && getVariation()) {
        return stockStatus.reduce((acc, stockItem) => {
          if (stockItem.variations && stockItem.variations.length > 0) {
            return stockItem.variations.reduce(
              (variationsAcc, variationItem) => {
                return variationItem.id === getVariation().variationId
                  ? variationItem
                  : variationsAcc
              },
              acc
            )
          } else {
            return acc
          }
        }, null)
      } else {
        return null
      }
    } else {
      return null
    }
  }

  const getStockStatus = () => {
    return getVariationStock()?.message || null
  }

  const getStockQuantity = () => {
    return getVariationStock()?.quantity || null
  }

  const purchaseAllowed = () => {
    if (type === PRODUCT_TYPES.VARIABLE && getVariation()) {
      return getStockQuantity() > 0 || getVariation().backordersAllowed
    } else {
      return false
    }
  }

  // Perhaps look at grammatic quirks later
  const getPartOfSerieTitle = () => {
    return `${getTranslation('seriesPartOfText')} ${
      productSerie.title
    }${getTranslation('seriesText')}`
  }

  const getMoreModelsInSerieTitle = () => {
    return `${getTranslation('seriesModelsText')} ${productSerie.title}
    ${getTranslation('seriesText')}`
  }

  /**
   * Fetch stock status and track view_item event
   */
  useEffect(() => {
    dispatch(doFetchStockStatus())
    gtag('view_item', {
      ecommerce: {
        items: [
          {
            item_name: name,
            item_id: getVariation().sku,
            price: getPrice(),
            item_brand: 'Ecoride',
            item_category: 'Bike',
            index: 1,
            quantity: 1,
          },
        ],
      },
    })
  }, [])

  const sameSerieProducts =
    products && products.length > 0
      ? products.filter(product => {
          return product.acfAttributes.productSerie && productSerie
            ? product.acfAttributes.productSerie.id === productSerie.id &&
                product.id !== id
            : false
        })
      : null
  return (
    <Layout>
      <SEO {...parseSeo(seo)} />
      <Breadcrumbs breadcrumbs={seo.breadcrumbs} />
      <PageContent>
        {/* <PageGallery
          images={[...productImage, ...images]}
          mediaItems={productAdditionalMedia}
        /> */}
        <PageGallery images={[...getImages()]} />
        <Container>
          <Divider
            size={{
              desktop: SPACING.MEDIUM,
            }}
          />
          <IndentWrapper col={3}>
            <Grid col={purchaseEnabled ? 2 : 1} mobileCol={1}>
              <Col style={{ marginLeft: '-15px', marginRight: '-15px' }}>
                <Content>
                  <Text>
                    <strong>{getName()}</strong>
                    <br />

                    {getSku() &&
                      `${getTranslation(
                        'singleProductNumberText'
                      )}:  ${getSku()} `}
                    <br />
                    <br />

                    <span
                      dangerouslySetInnerHTML={{
                        __html: `${formatPrice(getPrice())} ${getTranslation(
                          'singleProductPriceText'
                        )}`,
                      }}
                    />

                    {partialPayment && (
                      <span>
                        <br />
                        {partialPayment}
                      </span>
                    )}
                  </Text>
                </Content>

                {type === PRODUCT_TYPES.VARIABLE &&
                  variations &&
                  variations.nodes &&
                  variations.nodes.length > 0 && (
                    <>
                      <ColorPicker
                        interactive
                        variations={variations.nodes}
                        changeHandler={variatonId =>
                          setCurrentProductId(variatonId)
                        }
                      />
                      {getStockStatus() && (
                        <>
                          <Divider size={SPACING.X_SMALL} />
                          <StyledProductCard.StockMessage>
                            {getStockStatus()}
                          </StyledProductCard.StockMessage>
                        </>
                      )}
                    </>
                  )}
              </Col>
              {purchaseEnabled && (
                <Col>
                  <Button
                    handleClick={addToCart}
                    large
                    fullWidth
                    textColor={COLORS.SECONDARY_LIGHT_BLUE}
                    disabled={!purchaseAllowed()}
                  >
                    {getTranslation('addToCartText')}
                  </Button>
                </Col>
              )}
            </Grid>

            <Divider
              size={{
                desktop: SPACING.LARGE,
                tablet: SPACING.MEDIUM,
                mobile: SPACING.MEDIUM,
              }}
              line={COLORS.ADAPTED_LIGHT_BLUE}
            />

            <Content>
              <Text>
                <strong>{name}</strong>
              </Text>
              {productTitle && (
                <Heading size={1} type={'h1'}>
                  {productTitle}
                </Heading>
              )}
              {shortDescription && (
                <Text columns={1}>
                  <span
                    dangerouslySetInnerHTML={{ __html: shortDescription }}
                  />
                </Text>
              )}
            </Content>
          </IndentWrapper>
          <Divider
            size={{
              desktop: SPACING.LARGE,
              tablet: SPACING.MEDIUM,
              mobile: SPACING.MEDIUM,
            }}
            line={COLORS.ADAPTED_LIGHT_BLUE}
          />

          {productFeatures && (
            <>
              <FeatureGrid features={productFeatures} />
              <Divider
                size={{
                  desktop: SPACING.MEDIUM,
                  tablet: SPACING.MEDIUM,
                  mobile: SPACING.SMALL,
                }}
              />
            </>
          )}

          <Grid col={3}>
            <Col>
              <Content>
                <Text>{getPartOfSerieTitle()}</Text>
                <Text>{productSerie.acfAttributes.serieSummary}</Text>
                <TextLink
                  to={getUrl(productSerie.uri)}
                  text={`${getTranslation('discoverText')} ${
                    productSerie.title
                  }`}
                  underline
                />
              </Content>
            </Col>
            <Col column={'2 / -1'}>
              {productSerieImage && productSerieImage.makeImage ? (
                <MakeImage makeImage={productSerieImage.makeImage} />
              ) : (
                productSerie.featuredImage &&
                productSerie.featuredImage.node &&
                productSerie.featuredImage.node.makeImage && (
                  <MakeImage
                    makeImage={productSerie.featuredImage.node.makeImage}
                  />
                )
              )}
            </Col>
          </Grid>

          <Divider
            size={{
              desktop: SPACING.XX_LARGE,
              tablet: SPACING.MEDIUM,
              mobile: SPACING.SMALL,
            }}
            line={COLORS.ADAPTED_LIGHT_BLUE}
          />

          {Object.keys(parsedProductSpecTree).length !== 0 && (
            <>
              {/* Used for linking purposes. Padding/margin to compensate for sticky menu. */}
              <div
                id='specifications'
                style={{
                  paddingTop: '100px',
                  marginTop: '-100px',
                  display: 'block',
                  visibility: 'hidden',
                }}
              />
              <SectionHeader text={getTranslation('specificationText')} />
              <Divider size={SPACING.SMALL} />
              <SpecificationsTable productSpecTree={parsedProductSpecTree} />
            </>
          )}

          {sameSerieProducts && sameSerieProducts.length > 0 && (
            <>
              <Divider
                size={{
                  desktop: SPACING.XX_LARGE,
                  tablet: SPACING.MEDIUM,
                  mobile: SPACING.SMALL,
                }}
                line={COLORS.ADAPTED_LIGHT_BLUE}
              />
              <Grid col={3} mobileCol={1}>
                <Col>
                  <Content>
                    <Heading size={3} type={'h3'}>
                      {getMoreModelsInSerieTitle()}
                    </Heading>
                    <TextLink
                      to={getUrl(productSerie.uri)}
                      text={`${getTranslation('readMoreText')} ${
                        productSerie.title
                      }`}
                      underline
                    />
                  </Content>
                </Col>
                <Col column={'2 / -1'}>
                  <Grid col={3} mobileCol={2}>
                    {sameSerieProducts.map((product, index) => {
                      return <ProductCard key={product.id} product={product} />
                    })}
                  </Grid>
                </Col>
              </Grid>
            </>
          )}
          <br />
          <br />
          <br />

          <Divider size={SPACING.SMALL} line={COLORS.ADAPTED_LIGHT_BLUE} />
        </Container>
      </PageContent>
    </Layout>
  )
}

export const query = graphql`
  query($id: ID!) {
    wordpress {
      product(id: $id) {
        ...Single_Product
      }
    }
  }
`
