import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import 'basiclightbox/dist/basicLightbox.min.css'
import * as basicLightbox from 'basiclightbox'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import SlickSlider from 'react-slick'

import { getYoutubeId } from '../../helpers/utilities'
import StyledSlider from './style'
import { Icon } from '../Icon'
import { MakeImage } from '../MakeImage'

const Slider = ({ images, hideZoom = false }) => {
  const lightboxInstance = useRef(null)

  const mainSliderRef = useRef(null)
  const lightboxSliderRef = useRef(null)

  const [mainSlider, setMainSlider] = useState(null)
  const [lightboxSlider, setLightboxSlider] = useState(null)

  const openLightBox = () => {
    lightboxInstance.current.show()
  }

  const closeLightBox = () => {
    lightboxInstance.current.close()
  }

  const settings = {
    nextArrow: <SliderArrow direction={'next'} />,
    prevArrow: <SliderArrow direction={'prev'} />,
    dots: true,
    appendDots: appendDots,
    infinite: false,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  useEffect(() => {
    lightboxInstance.current = basicLightbox.create(
      document.querySelector('#lightboxSlider'),
      {
        className: 'eco-lightbox-gallery',
        closable: false,
      }
    )

    setMainSlider(mainSliderRef.current)
    setLightboxSlider(lightboxSliderRef.current)
  }, [])

  const openLightBoxVideo = url => {
    const videoId = getYoutubeId(url)
    const videoUrl = videoId ? `https://www.youtube.com/embed/${videoId}` : null

    if (videoUrl) {
      basicLightbox
        .create(
          `<div class="iframe-wrapper"><iframe
          src="${videoUrl}"
          frameborder='0'
          allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
          allowfullscreen
        /></div>`,
          {
            className: 'eco-lightbox-gallery eco-lightbox-video',
          }
        )
        .show()
    }
  }

  return (
    <>
      <StyledSlider>
        <SlickSlider
          {...settings}
          asNavFor={lightboxSlider}
          ref={mainSliderRef}
        >
          {images.map((item, index) => (
            <StyledSlider.ImageWrapper key={index}>
              <MakeImage makeImage={item.image} />
              {item.video !== null && (
                <StyledSlider.LightboxTriggerVid
                  onClick={() => openLightBoxVideo(item.video)}
                >
                  <Icon size={75} icon={'play_arrow'} />
                </StyledSlider.LightboxTriggerVid>
              )}
            </StyledSlider.ImageWrapper>
          ))}
        </SlickSlider>
        {!hideZoom && (
          <LightboxTrigger bottom handleClick={() => openLightBox()} />
        )}
      </StyledSlider>

      <div id={'lightboxSlider'}>
        <LightboxTrigger
          top
          icon={'clear'}
          handleClick={() => closeLightBox()}
        />
        <SlickSlider
          {...settings}
          asNavFor={mainSlider}
          ref={lightboxSliderRef}
        >
          {images.map((item, index) => (
            <StyledSlider.ImageWrapper key={index}>
              <MakeImage makeImage={item.image} />
              {item.video && (
                <StyledSlider.LightboxTriggerVid
                  onClick={() => openLightBoxVideo(item.video)}
                >
                  <Icon size={75} icon={'play_arrow'} />
                </StyledSlider.LightboxTriggerVid>
              )}
            </StyledSlider.ImageWrapper>
          ))}
        </SlickSlider>
      </div>
    </>
  )
}

export const SliderArrow = props => {
  const { direction, onClick } = props
  return (
    <StyledSlider.Arrow onClick={onClick} direction={direction}>
      <Icon
        size={40}
        icon={direction === 'next' ? 'chevron_right' : 'chevron_left'}
      />
    </StyledSlider.Arrow>
  )
}

const appendDots = dots => {
  return <StyledSlider.Dots>{dots}</StyledSlider.Dots>
}

const LightboxTrigger = ({ handleClick, icon = 'search', top, bottom }) => {
  return (
    <StyledSlider.LightboxTrigger
      onClick={handleClick}
      top={top}
      bottom={bottom}
    >
      <Icon size={28} icon={icon} />
    </StyledSlider.LightboxTrigger>
  )
}

Slider.propTypes = {
  images: PropTypes.array,
  hideZoom: PropTypes.bool,
}

export { Slider }
