import styled from 'styled-components'
import { spacing, colors, mq } from '../../styles/utilities/variables'
import StyledContainer from '../Container/style'

const StyledPageGallery = styled.div`
  background: ${colors.white};
  margin-top: -${props => (props.noMargin ? '0' : spacing.xxxLarge)};

  > ${StyledContainer} {
    padding: 0;
  }

  @media ${mq.tablet} {
    margin-top: -${props => (props.noMargin ? '0' : spacing.xLarge)};
  }

  @media ${mq.mobile} {
    margin-top: -${props => (props.noMargin ? '0' : spacing.medium)};
  }
`

export default StyledPageGallery
