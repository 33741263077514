import styled from 'styled-components'
import {
  spacing,
  colors,
  cssEasings,
  mq,
} from '../../styles/utilities/variables'

const StyledSlider = styled.div`
  position: relative;

  .slick-track {
    display: flex;
  }

  .slick-slide {
    display: flex;
    height: auto;
    align-items: center;

    > div {
      width: 100%;
      font-size: 0;
    }

    img {
      width: 100%;
    }
  }

  .slick-dots {
    bottom: 0;

    @media ${mq.mobile} {
      bottom: -5.4rem;
    }
  }

  @media ${mq.mobile} {
    padding-bottom: ${spacing.xLarge};
  }
`

StyledSlider.ImageWrapper = styled.div`
  position: relative;
`

StyledSlider.Arrow = styled.button`
  padding: 0;
  font-size: 0;
  width: 6rem;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.white};
  border-radius: 50%;
  border: none;
  outline: none;
  position: absolute;
  z-index: 3;
  top: 50%;
  transform: translateY(-50%);
  left: ${props => (props.direction === 'prev' ? spacing.xSmall : 'auto')};
  right: ${props => (props.direction === 'next' ? spacing.xSmall : 'auto')};

  @media ${mq.mobile} {
    display: none;
  }
`

StyledSlider.Dots = styled.ul`
  list-style: none;
  padding: 0 0 ${spacing.medium};
  bottom: 0;
  margin: 0;
  display: flex;
  justify-content: center;

  li {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0 ${spacing.xxSmall};

    button {
      width: 1.2rem;
      height: 1.2rem;
      background: ${colors.secondaryLightBlue};
      transition: background-color 0.2s ${cssEasings.easeOutSine};
      border-radius: 50%;

      &:before {
        display: none;
      }
    }

    &.slick-active {
      button {
        background: ${colors.primaryDarkBlue};
      }
    }
  }
`

StyledSlider.Dot = styled.li`
  width: 1.2rem;
  height: 1.2rem;
  background: ${props => (props.active ? colors.grey : colors.lightGrey)};
  border-radius: 100%;
`

StyledSlider.LightboxTrigger = styled.button`
  background: transparent;
  font-size: 0;
  border: none;
  outline: none;
  position: absolute;
  right: ${spacing.xSmall};
  z-index: 1;
  ${props => (props.top ? `top: ${spacing.xSmall};` : '')}
  ${props => (props.bottom ? `bottom: ${spacing.xSmall};` : '')}
  width: 3.6rem;
  height: 3.6rem;
  margin: 0;
  padding: 0;
  background: ${colors.white};
  border-radius: 50%;

  @media ${mq.mobile} {
    display: none;
  }
`

StyledSlider.LightboxTriggerVid = styled.button`
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  font-size: 0;
  border: none;
  outline: none;
  position: absolute;
  left: 50%;
  top: 50%;
  padding: 5px;
  transform: translateY(-50%) translateX(-50%);
  i {
    color: ${colors.primaryRed};
  }
`

export default StyledSlider
